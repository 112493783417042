import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { ExternalLink } from 'components/ExternalLink'
import { Label } from 'components/Label'
import { Link } from 'components/Link'
import { ProgressBar } from 'components/ProgressBar'
import { Table } from 'components/Table'
import { TransactionButton } from 'components/TransactionButton'
import {
  DECIMALS,
  EXIT_TRANSACTION_TYPE,
  TRANSACTION_TYPE_LABELS,
  TXN_STATUS_ICONS,
  TXN_STATUS_VARIANTS,
} from 'constants/common'
import { Routes } from 'constants/routes'
import { env } from 'env.client'
import { RecentTransactionsListItemExitStatus as TxItemExitStatus } from 'generated/reactQueryClient'
import type { RecentTransactionsListItem as TxItem } from 'generated/reactQueryClient'
import { useTransactionHashTruncateOptions } from 'hooks/useTransactionHashTruncateOptions'
import type { UpdatedTransactionType } from 'hooks/useUpdatedTransactions'
import {
  doesTransactionHaveValidExitStatus,
  getAssetSymbol,
  getFormattedNumber,
  weiToEth,
} from 'utils/common'
import { getTruncatedText } from 'utils/getTruncatedText'

type RecentTransactionsTableProps = {
  data: UpdatedTransactionType[]
  isLoading: boolean
  txInExecution: string | null
  setIsExecuted: (isExecuted: boolean) => void
  setSelectedTransactionL2Hash: (l2TxHash: string) => void
  handleActionButton: (row: TxItem) => Promise<void>
  handleOnTransactionFinalized: () => Promise<void>
}

export const RecentTransactionsTable = ({
  data,
  isLoading,
  txInExecution,
  setIsExecuted,
  setSelectedTransactionL2Hash,
  handleActionButton,
  handleOnTransactionFinalized,
}: RecentTransactionsTableProps) => {
  const { t } = useTranslation(['common'])
  const TRUNCATE_TRANSACTION_HASH_OPTIONS = useTransactionHashTruncateOptions()

  return (
    <Table
      maxW={{ base: 600, md: 895 }}
      w="100%"
      data={data}
      hasPagination={false}
      title={t('Page.Home.RecentTransactions')}
      titleTooltip={t('Page.Home.RecentTransactionsTooltip')}
      isLoading={isLoading}
      loaderHeight="24.75rem"
      additionalHeaderContent={
        <Link href={Routes.transactions()} variant="secondary" whiteSpace="nowrap" ml="auto">
          {t('Page.Home.SeeAllTransactions')}
        </Link>
      }
      renderMobileRow={(row: UpdatedTransactionType) => {
        const hasValidExitStatus = doesTransactionHaveValidExitStatus(row.exitStatus)

        return (
          <Table.MobileRow key={row.l2TxHash}>
            {({ isExpanded }) => (
              <Flex flex={1} flexDirection="column" textAlign="left" gap={3}>
                <Table.MobileRowCell label={`${t('TransactionsTable.Status')}:`}>
                  <Label
                    variant={TXN_STATUS_VARIANTS[row.status]}
                    icon={TXN_STATUS_ICONS[row.status]}
                  >
                    {row.status}
                  </Label>
                </Table.MobileRowCell>
                <Table.MobileRowCell label={`${t('TransactionsTable.Type')}:`}>
                  <Flex flexDirection="column">
                    <Text variant="text2medium">
                      {
                        TRANSACTION_TYPE_LABELS[
                          row?.txType?.toLowerCase() as keyof typeof TRANSACTION_TYPE_LABELS
                        ]
                      }
                    </Text>
                    <ExternalLink
                      variant="secondary"
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                      {...(row.txType === EXIT_TRANSACTION_TYPE &&
                      ![
                        TxItemExitStatus.waiting,
                        TxItemExitStatus.ready_to_prove,
                        TxItemExitStatus.in_challenge_period,
                      ].some((status) => row.exitStatus === status)
                        ? {
                            href: `${env.NEXT_PUBLIC_L1_URL}/tx/${row.l1TxHash}`,
                            label: getTruncatedText(
                              row?.l1TxHash ?? '',
                              ...TRUNCATE_TRANSACTION_HASH_OPTIONS,
                            ),
                          }
                        : {
                            href: `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${row.l2TxHash}`,
                            label: getTruncatedText(
                              row?.l2TxHash ?? '',
                              ...TRUNCATE_TRANSACTION_HASH_OPTIONS,
                            ),
                          })}
                    />
                  </Flex>
                </Table.MobileRowCell>
                <Table.MobileRowCell label={`${t('TransactionsTable.Amount')}:`}>
                  <Text variant="text2medium">
                    {getFormattedNumber(
                      weiToEth(
                        row.transfers?.[0]?.wei ?? row.value,
                        row.transfers?.[0]?.decimals ?? DECIMALS,
                      ),
                    )}{' '}
                    {getAssetSymbol(row?.transfers?.[0]?.symbol)}
                  </Text>
                </Table.MobileRowCell>
                <Table.AnimatedMobileRowCell
                  label={`${t('TransactionsTable.Phase')}:`}
                  isVisible={isExpanded}
                >
                  <ProgressBar
                    status={row.txType === EXIT_TRANSACTION_TYPE ? row.exitStatus : row.status}
                  />
                </Table.AnimatedMobileRowCell>
                <Table.AnimatedMobileRowCell label={undefined} isVisible={isExpanded} flex={1}>
                  <TransactionButton
                    transaction={row}
                    txInExecution={txInExecution}
                    hasValidExitStatus={hasValidExitStatus}
                    onClick={(event) => {
                      event.stopPropagation()
                      if (hasValidExitStatus) {
                        setIsExecuted(false)
                        if (row.l2TxHash) {
                          setSelectedTransactionL2Hash(row.l2TxHash)
                        }
                      } else {
                        void handleActionButton(row)
                      }
                    }}
                    onTransactionFinalized={handleOnTransactionFinalized}
                    flex={1}
                  />
                </Table.AnimatedMobileRowCell>
              </Flex>
            )}
          </Table.MobileRow>
        )
      }}
      renderTableRow={(row: UpdatedTransactionType) => {
        const hasValidExitStatus = doesTransactionHaveValidExitStatus(row.exitStatus)

        return (
          <Table.Row key={row.l2TxHash}>
            <Table.RowCell>
              <Label variant={TXN_STATUS_VARIANTS[row.status]} icon={TXN_STATUS_ICONS[row.status]}>
                {row.status}
              </Label>
            </Table.RowCell>
            <Table.RowCell>
              <Text variant="title2medium" mb={1.5}>
                {
                  TRANSACTION_TYPE_LABELS[
                    row?.txType?.toLowerCase() as keyof typeof TRANSACTION_TYPE_LABELS
                  ]
                }
              </Text>
              <ExternalLink
                variant="secondary"
                {...(row.txType === EXIT_TRANSACTION_TYPE &&
                ![
                  TxItemExitStatus.waiting,
                  TxItemExitStatus.ready_to_prove,
                  TxItemExitStatus.in_challenge_period,
                ].some((status) => row.exitStatus === status)
                  ? {
                      href: `${env.NEXT_PUBLIC_L1_URL}/tx/${row.l1TxHash}`,
                      label: getTruncatedText(row?.l1TxHash ?? ''),
                    }
                  : {
                      href: `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${row.l2TxHash}`,
                      label: getTruncatedText(row?.l2TxHash ?? ''),
                    })}
              />
            </Table.RowCell>
            <Table.RowCell>
              <Text variant="text2medium">
                {getFormattedNumber(
                  weiToEth(
                    row.transfers?.[0]?.wei ?? row.value,
                    row.transfers?.[0]?.decimals ?? DECIMALS,
                  ),
                )}{' '}
                {getAssetSymbol(row?.transfers?.[0]?.symbol)}
              </Text>
            </Table.RowCell>
            <Table.RowCell>
              <ProgressBar
                status={row.txType === EXIT_TRANSACTION_TYPE ? row.exitStatus : row.status}
              />
            </Table.RowCell>
            <Table.RowCell>
              <TransactionButton
                transaction={row}
                txInExecution={txInExecution}
                hasValidExitStatus={hasValidExitStatus}
                onClick={() => {
                  if (hasValidExitStatus) {
                    setIsExecuted(false)
                    if (row.l2TxHash) {
                      setSelectedTransactionL2Hash(row.l2TxHash)
                    }
                  } else {
                    void handleActionButton(row)
                  }
                }}
                onTransactionFinalized={handleOnTransactionFinalized}
              />
            </Table.RowCell>
          </Table.Row>
        )
      }}
    />
  )
}
