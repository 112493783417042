import { Decimal } from 'decimal.js'
import type { TFunction } from 'i18next'
import { DEFAULT_VALUE } from 'constants/common'
import { ETH } from 'constants/network'
import { captureError } from 'utils/sentry'

type GetBridgeButtonProps = {
  amount?: string
  ethBalance?: string
  selectedAssetBalance?: string
  gasFee?: string
  asset: string
  t: TFunction<['common'], undefined>
  from: {
    id: number
    label: string
  }
  customReceiverState: {
    isEnabled: boolean
    address: string
    isValidAddress: boolean
  }
  isConnectedAddressMultisigWallet: boolean
}

const safeParseDecimal = (value: string): Decimal | null => {
  try {
    // *: Remove commas for locale strings (e.g. "1,234,567.89")
    const parsedValue = new Decimal(value.replaceAll(',', ''))
    return parsedValue
  } catch (error) {
    if (error instanceof Error) {
      captureError(error)
    }
    return null
  }
}

export const getBridgeButtonProps = ({
  amount = DEFAULT_VALUE,
  ethBalance = DEFAULT_VALUE,
  selectedAssetBalance = DEFAULT_VALUE,
  gasFee = DEFAULT_VALUE,
  asset,
  t,
  customReceiverState,
  isConnectedAddressMultisigWallet,
}: GetBridgeButtonProps) => {
  const amountD = safeParseDecimal(amount || '0')
  const ethBalanceD = safeParseDecimal(ethBalance || '0')
  const selectedAssetBalanceD = safeParseDecimal(selectedAssetBalance || '0')
  const gasFeeD = safeParseDecimal(gasFee || '0')

  // Check if any of the parsed values are null
  if (!gasFeeD || !amountD || !ethBalanceD || !selectedAssetBalanceD) {
    return { isDisabled: true, label: t('Page.Home.BridgeButton.InvalidInput') }
  }

  if (
    isConnectedAddressMultisigWallet &&
    (!customReceiverState.address || !customReceiverState.isValidAddress)
  ) {
    return { isDisabled: true, label: t('Page.Home.BridgeButton.EnterValidReceiverAddress') }
  }

  if (!amount) {
    return { isDisabled: true, label: t('Page.Home.BridgeButton.EnterAmountToBridge') }
  }

  if (amountD.greaterThan(selectedAssetBalanceD) || amountD.lessThan(0)) {
    return { isDisabled: true, label: t('Page.Home.BridgeButton.NotEnoughFunds') }
  }

  if (
    (asset === ETH.symbol && amountD.plus(gasFeeD).greaterThan(selectedAssetBalanceD)) ||
    (asset !== ETH.symbol && gasFeeD.greaterThan(ethBalanceD))
  ) {
    return {
      isDisabled: true,
      label: t('Page.Home.BridgeButton.NotEnoughGas', { gasValue: gasFee }),
    }
  }

  if (customReceiverState.isEnabled && !customReceiverState.address) {
    return { isDisabled: true, label: t('Page.Home.BridgeButton.EnterReceiverAddress') }
  }

  if (customReceiverState.isEnabled && !customReceiverState.isValidAddress) {
    return { isDisabled: true, label: t('Page.Home.BridgeButton.EnterValidReceiverAddress') }
  }

  return { isDisabled: false, label: t('Page.Home.BridgeButton.Bridge') }
}
